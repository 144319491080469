import {
  Breadcrumb,
  BreadcrumbItem,
} from "@appkit4/react-components/breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import "../assets/css/Header.css";
import { useEffect, useState, useContext, useCallback, useRef } from "react";
import { UserContext } from "../../src/UserContext";
import { SocketContext } from "../hooks/use-socket";
import AuditFilterBreadcrumb from "./AuditFilterBreadcrumb";
import { IUserContext } from "../models/user.model";
import { Spinner } from "react-bootstrap";
import { useDebouncedCallback } from "use-debounce";
import useHttp from "../hooks/useHttp";
import APIService from "../services/api.service";

const HeaderApp = () => {
  const user: IUserContext = useContext(UserContext);
  const socket: any = useContext(SocketContext);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [module, setModule] = useState("");
  const [subModule, setSubModule] = useState("");
  const [isFilter, setIsFilter] = useState({} as any);
  const [notifications, setNotifications] = useState([]);
  const [counterLength, setCounterLength] = useState(0);
  const [page, setPage] = useState(0);
  const [updateIndex, setUpdateIndex] = useState(-1);
  const navigate = useNavigate();
  const observer: any = useRef();
  const { sendRequest: readNotification } = useHttp();

  useEffect(() => {
    setModule(location.state?.module);
    setSubModule(location.state?.submodule);
    setIsFilter(location.state?.filter);
  }, [
    location.state?.module,
    location.state?.submodule,
    location.state?.filter,
  ]);

  const refreshNotifications = useDebouncedCallback(() => {
    setPage(0);
    socket.receiveNotification(
      user.userData.userid,
      setNotifications,
      setCounterLength,
      0
    );
  }, 60000);

  useEffect(() => {
    refreshNotifications();
  }, [notifications]);

  const loadMore = useDebouncedCallback(
    () =>
      socket.receiveNotification(
        user.userData.userid,
        setNotifications,
        setCounterLength,
        page,
        notifications
      ),
    1000
  );

  useEffect(() => {
    loadMore();
  }, [page]);

  const scrollRef: any = useCallback(
    (node: any) => {
      if (socket?.loading) return;
      if (observer.current) observer.current?.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && socket?.hasMore) {
          setPage((prevPage) => prevPage + 1); // trigger loading of new posts by chaging page no
          socket?.setLoading(true);
        }
      });

      if (node) observer.current.observe(node);
    },
    [socket?.loading, socket?.hasMore]
  );

  const displayNotification = (value: any, index: number) => {
    let tempDate = value?.created_at?.split(" ")[0];
    let year = tempDate?.split("-")[0];
    let month = tempDate?.split("-")[1];
    let date = tempDate?.split("-")[2];
    tempDate = date + "/" + month + "/" + year;
    return (
      <div
        key={value.bell_notification_id + "_" + index}
        ref={notifications.length === index + 1 ? scrollRef : null}
      >
        {updateIndex !== index && (
          <span
            className={value?.is_read ? "notificationRead" : "notification"}
            onClick={() => notificationRead(value.bell_notification_id, index)}
            onKeyUp={(e) => e.preventDefault()}
          >
            {value?.is_read ? (
              <div>
                <div dangerouslySetInnerHTML={{ __html: value.content }}></div>
                <span className="dateStyle">{tempDate}</span>
              </div>
            ) : (
              <div className="row">
                <div className="col-1 unreadDot" />
                <div className="col-11">
                  <div
                    dangerouslySetInnerHTML={{ __html: value.content }}
                  ></div>
                  <span className="dateStyleRead">{tempDate}</span>
                </div>
              </div>
            )}

            <hr className="breakLine"></hr>
          </span>
        )}
        {((socket?.loading && notifications.length === index + 1) ||
          updateIndex === index) && (
          <div className="content-loading">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </div>
    );
  };

  const readResponse = (json: any, notification_id: number) => {
    if (json.status === "success") {
      setUpdateIndex(-1);
      setNotifications((prev: any): any => {
        const index = prev.indexOf(
          prev.find((i: any) => i.bell_notification_id === notification_id)
        );
        return [
          ...[
            ...prev.slice(0, index),
            { ...prev[index], is_read: 1 },
            ...prev.slice(index + 1),
          ].filter((value, i, array) => array.indexOf(value) === i),
        ];
      });
    }
  };

  const notificationRead = (notificationId: any, index: number) => {
    setUpdateIndex(index);
    readNotification(
      APIService.readNotification({ bell_notification_id: notificationId }),
      (data: any) => readResponse(data, notificationId),
      (err) => {
        setUpdateIndex(-1);
      }
    );
  };

  const displaySingle = () => {
    return <span className="notificationRead">No notifications</span>;
  };

  return (
    <div className="header-row">
      <div>
        <Breadcrumb>
          {!user?.userData?.user_type ? (
            <BreadcrumbItem onClick={() => navigate("/")}>
              <span className="wt_label2" tabIndex={0}>
                {module ? (
                  <span className="Appkit4-icon icon-home-fill"></span>
                ) : (
                  "Dashboard"
                )}
              </span>
            </BreadcrumbItem>
          ) : (
            <></>
          )}
          {module ? (
            <BreadcrumbItem>
              <span className="wt_label2" tabIndex={0}>
                {module}
              </span>
            </BreadcrumbItem>
          ) : (
            <></>
          )}
          {subModule ? (
            <BreadcrumbItem>
              <span className="wt_label2" tabIndex={0}>
                <b>{subModule}</b>
              </span>
            </BreadcrumbItem>
          ) : (
            <></>
          )}
          {isFilter?.client && (
            <AuditFilterBreadcrumb
              handleChange={() => {}}
              setLoader={() => {}}
              userData={user.userData}
              options={isFilter}
            />
          )}
        </Breadcrumb>
      </div>

      <div className="top-icons">
        <span
          className="Appkit4-icon icon-notification-outline ap-font-16 top-icon cursor-pointer"
          title="notification"
          onClick={() => setOpen(!open)}
          onKeyUp={(e) => e.preventDefault()}
        ></span>
        {counterLength !== 0 ? <div className="counter"></div> : ""}
      </div>
      {open && (
        <div className="notifications">
          <div className="notificationHeader">
            <h3 className="notifyStyle">Notifications</h3>
            <div>
              <span className="count">Unread count: </span>
              {counterLength}
            </div>
          </div>
          <div id="scrollStyle" className="notification-content">
            {notifications?.length === 0
              ? displaySingle()
              : notifications?.map((element: any, index: number) => {
                  return displayNotification(element, index);
                })}
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderApp;
