import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const getAlertClients = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.ALERT_CLIENTS,
  };
};

const getAlertProjectDropdown = (clientId: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.ALERT_PROJECTS}/${clientId}`,
  };
};

const getNotificationList = (
  clientId: number,
  projectId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.NOTIFICATION_LIST}/${clientId}/${projectId}`,
  };
};

const updateEmailNotification = (data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: `${URLS.NOTIFICATION_UPDATE}`,
    body: data,
  };
};

const getNotificationType = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.NOTIFICATION_TYPE,
  };
};
const getNotificationSubType = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.NOTIFICATION_SUB_TYPE,
  };
};
const getOperation = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.NOTIFICATION_OPERATION,
  };
};
const getModule = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.NOTIFICATION_MODULE,
  };
};
const getSubModule = (module: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.NOTIFICATION_SUBMODULE}/${module}`,
  };
};

const editNotification = (data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: `${URLS.NOTIFICATION_EDIT}`,
    body: data,
  };
};

const readNotification = (data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: `${URLS.NOTIFICATION_READ}`,
    body: data,
  };
};

const appManagementAlertServices = {
  getAlertClients,
  getAlertProjectDropdown,
  getNotificationList,
  updateEmailNotification,
  getNotificationType,
  getNotificationSubType,
  getOperation,
  getModule,
  getSubModule,
  editNotification,
  readNotification,
};

export default appManagementAlertServices;
