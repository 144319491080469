import { useState, createContext, useMemo, useCallback } from "react";
import { io, Socket } from "socket.io-client";

export const SocketContext: any = createContext({});

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const socketUrl: any =
    process.env.REACT_APP_IAMT_BACK_END_SERVICE_PORT_SOCKET;

  const socketConnect = useCallback(() => {
    if (!socket?.connected) {
      const newSocket = io(socketUrl, {
        path: "/sockets",
        transports: ["websocket"],
        autoConnect: false,
      });

      newSocket.connect();
      setSocket(newSocket);
    }
  }, [socket?.connected, socketUrl]);

  const socketDisconnect = useCallback(() => {
    if (socket?.connected) {
      socket.disconnect();
      setSocket(null);
    }
  }, [socket]);

  const receiveNotification = useCallback(
    (
      userid: any,
      setNotifications: any,
      setCounterlength: any,
      page: number,
      notifications?:any,
    ) => {
      if (page !== null) {
        socket?.emit("unread_notification", userid, page);
        socket?.on("response", (json: any) => {
          if (page > 0) {
            setNotifications((prev: any) =>
              [
                ...[...notifications, ...json.notifications].filter(
                  (value, i, array) => array.indexOf(value) === i
                ),
              ]
            );
          } else {
            setNotifications((prev: any) => [...json.notifications]);
          }
          setCounterlength(json.Total);
          setHasMore(json.notifications?.length > 0);
          setLoading(false);
        });
      }
    },
    [socket]
  );

  const readNotification = useCallback(
    (
      userid: any,
      notification_id: any,
      setNotifications: any,
      notifications: any,
      setUpdateIndex: any
    ) => {
      socket?.emit("read_notification", userid, notification_id);
      socket?.on("response_read", (json: any) => {
        if (json.status === "success") {
          setUpdateIndex(-1);
          setNotifications((prev: any) => {
            const index = prev.indexOf(
              notifications.find(
                (i: any) => i.bell_notification_id === notification_id
              )
            );
            return [
              ...prev.slice(0, index),
              { ...prev[index], is_read: 1 },
              ...prev.slice(index + 1),
            ];
          });
        }
      });
    },
    [socket]
  );

  const values = useMemo(
    () => ({
      socket,
      hasMore,
      loading,
      setLoading,
      socketConnect,
      socketDisconnect,
      receiveNotification,
      readNotification,
    }),
    [
      socket,
      hasMore,
      loading,
      setLoading,
      socketConnect,
      socketDisconnect,
      receiveNotification,
      readNotification,
    ]
  );

  return (
    <SocketContext.Provider value={values}>{children}</SocketContext.Provider>
  );
};
