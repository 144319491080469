import { Input } from "@appkit4/react-components/field";
import { useState, useEffect, Fragment } from "react";
import { Select } from "@appkit4/react-components";
import { toast } from "react-toastify";
import "../../../assets/css/Common.css";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "@appkit4/react-components/button";

import { useParams, useNavigate } from "react-router-dom";
import useHttp from "../../../hooks/useHttp";
import APIService from "../../../services/api.service";
import { TextEditor } from "@appkit4/react-text-editor";
import "@appkit4/react-text-editor/dist/appkit4-react-texteditor.min.css";

const EditAlert = () => {
  const { id, client, project } = useParams();
  const [editFormData, setEditFormData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [createFlag, setCreateFlag] = useState(false);
  const [module, setModule] = useState<any>([]);
  const [subModule, setSubModule] = useState<any>([]);
  const [notificationType, setNotificationType] = useState<any>([]);
  const [notificationSubType, setNotificationSubType] = useState<any>([]);
  const [operation, setOperation] = useState<any>([]);
  const { sendRequest: notificationTypeAPI } = useHttp();
  const { sendRequest: notificationSubTypeAPI } = useHttp();
  const { sendRequest: operationAPI } = useHttp();
  const { sendRequest: moduleList } = useHttp();
  const { sendRequest: subModuleList } = useHttp();
  const { sendRequest: getNotificationList } = useHttp();
  const { sendRequest: editNotification } = useHttp();
  const navigate = useNavigate();

  useEffect(() => {
    fetchdata(client, project);
    fetchModuleList();
    fetchNotificationType();
    fetchOperation();
    fetchNotificationSubType();
    if (editFormData?.module_id) {
      fetchSubModuleList(editFormData?.module_id);
    }
    navigate(`/app-management/Alert/update/${id}/${client}/${project}`, {
      state: { module: "Setup", submodule: "Alert" },
    });
  }, [id, client, project]);

  const fetchdata = async (client: any, project: any) => {
    await getNotificationList(
      APIService.getNotificationList(client, project),
      (json: any) => {
        if (Array.isArray(json?.data)) {
          const result = json?.data?.find(
            (type: any) => type.notification_id === Number(id)
          );
          setEditFormData(result);
          setIsLoading(false);
        }
      }
    );
  };

  const onChangeSubType = (e: any) => {
    let sub_type = "";
    notificationSubType.forEach((type: any) => {
      if (type.value === e) {
        sub_type = type.label;
      }
    });
    setEditFormData({ ...editFormData, sub_type_id: e, sub_type: sub_type });
  };

  const onChangeDays = (e: any) => {
    setEditFormData({ ...editFormData, days: e });
  };
  const onChangeFrequency = (e: any) => {
    setEditFormData({ ...editFormData, frequency: e });
  };
  const onChangeSubject = (e: any) => {
    setEditFormData({ ...editFormData, subject: e });
  };
  const onChangeDescription = (e: any, editor: any, data: any) => {
    setEditFormData({ ...editFormData, content: data });
  };

  const checkSubject = () => {
    if (editFormData.notification_type_id === emailType) {
      return editFormData.subject !== "";
    } else {
      return true;
    }
  };

  const checkSchedule = () => {
    if (editFormData.sub_type_id !== taskCompletionType) {
      return (
        editFormData.days !== "" &&
        editFormData.days !== null &&
        editFormData.frequency !== "" &&
        editFormData.frequency !== null
      );
    } else {
      return true;
    }
  };

  const validateForm: any = () => {
    return (
      editFormData.module !== "" &&
      editFormData.sub_module !== "" &&
      editFormData.operation !== "" &&
      editFormData.sub_type !== "" &&
      editFormData.client_id !== "" &&
      editFormData.project_id !== "" &&
      checkSubject() &&
      checkSchedule() &&
      editFormData.content !== ""
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setCreateFlag(true);
    if (validateForm()) {
      const submitData = {
        notification_id: editFormData.notification_id,
        client_id: editFormData.client_id,
        project_id: editFormData.project_id,
        module_id: editFormData.module_id,
        sub_module_id: editFormData.sub_module_id,
        notification_type_id: editFormData.notification_type_id,
        sub_type_id: editFormData.sub_type_id,
        operation_id: editFormData.operation_id,
        header: editFormData.header,
        subject: editFormData.subject,
        content: editFormData.content,
        days: editFormData.days,
        frequency: editFormData.frequency,
      };
      editNotification(APIService.editNotification(submitData), updateResposne);
    }
  };

  const updateResposne = (json: any) => {
    setIsLoading(true);
    if (json.status === "success") {
      handleBack();
      toast.success("Notification created successfully!!");
      setIsLoading(false);
    } else {
      toast.error("Notification creation failed!!");
    }
  };

  const fetchNotificationType = () => {
    notificationTypeAPI(APIService.getNotificationType(), (json: any) => {
      setNotificationType(json?.data);
    });
  };
  const fetchNotificationSubType = () => {
    notificationSubTypeAPI(APIService.getNotificationSubType(), (json: any) => {
      setNotificationSubType(json?.data);
    });
  };

  const fetchOperation = () => {
    operationAPI(APIService.getOperation(), (json: any) => {
      setOperation(json.data);
    });
  };

  const fetchModuleList = () => {
    moduleList(APIService.getModule(), (json: any) => {
      setModule(json.ModuleList);
    });
  };

  const fetchSubModuleList = (module: any) => {
    subModuleList(APIService.getSubModule(module), (json: any) => {
      setSubModule(json.data);
    });
  };

  const handleBack = () => {
    navigate("/app-management/Alert", {
      state: { module: "App Management", submodule: "Alert" },
    });
  };

  const sampleConfig = {
    toolbar: [
      "fontFamily",
      "fontSize",
      "bold",
      "italic",
      "strikethrough",
      "underline",
      "bulletedList",
      "numberedList",
      "indent",
      "outdent",
      "alignment:left",
      "alignment:center",
      "alignment:right",
      "alignment:justify",
      "link",
      "undo",
      "redo",
    ],
  };

  const taskCompletionType = notificationSubType?.find((i: any) =>
    i?.label?.toLowerCase().includes("task")
  )?.value;

  const emailType = notificationType.find((i: any) =>
    i?.label?.toLowerCase().includes("email")
  )?.value;

  const getContent = () =>{
    return editFormData?.content?.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  }

  return (
    <Fragment>
      {isLoading && (
        <div className="content-loading">
          <Spinner animation="border">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {!isLoading && (
        <div className="mt-2 alertAlignment">
          <h6>Client : {editFormData?.client}</h6>
          <h6>Project : {editFormData?.project}</h6>

          <form>
            <div className="row mt-3">
              <div className="col-md-6">
                <Select
                  data={module}
                  searchable={true}
                  searchPlaceholder="Search module"
                  placeholder={"Select module"}
                  value={editFormData?.module_id}
                  disabled
                ></Select>
              </div>
              <div className="col-md-6">
                <Select
                  data={subModule}
                  searchable={true}
                  searchPlaceholder="Search submodule"
                  placeholder={"Select submodule"}
                  value={editFormData?.sub_module_id}
                  disabled
                ></Select>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <Select
                  data={operation}
                  searchable={true}
                  searchPlaceholder="Search operation"
                  placeholder={"Select operation"}
                  value={editFormData?.operation_id}
                  disabled
                ></Select>
              </div>
              <div className="col-md-6">
                <Select
                  data={notificationType}
                  searchable={true}
                  searchPlaceholder="Search type"
                  placeholder={"Select type"}
                  value={editFormData?.notification_type_id}
                  disabled
                ></Select>
              </div>
            </div>
            {editFormData?.notification_type_id !== "1" && (
              <div className="row mt-3">
                <div className="col-md-6">
                  <Select
                    data={notificationSubType}
                    searchable={true}
                    searchPlaceholder="Search Notification Type"
                    placeholder={"Select Notification Type"}
                    value={editFormData?.sub_type_id}
                    onSelect={onChangeSubType}
                    required={true}
                  ></Select>
                  {editFormData?.sub_type_id === "" && createFlag && (
                    <span className="required-label">
                      This field is required!
                    </span>
                  )}
                </div>
                {editFormData?.notification_type_id !== "1" &&
                  editFormData?.sub_type_id !== taskCompletionType && (
                    <div className="col-md-6">
                      <Input
                        type={"number"}
                        title={"Frequency in days "}
                        className="textFieldWidth"
                        name="Frequency in days"
                        value={editFormData?.frequency}
                        onChange={onChangeFrequency}
                        required={true}
                      ></Input>
                      {editFormData?.frequency === "" ||
                        (editFormData?.frequency === null && createFlag && (
                          <span className="required-label">
                            This field is required!
                          </span>
                        ))}
                    </div>
                  )}
              </div>
            )}

            <div className="row mt-3">
              {editFormData?.notification_type_id !== "1" &&
                editFormData?.sub_type_id !== taskCompletionType && (
                  <div className="col-md-6">
                    <Input
                      type={"number"}
                      title={"How many days "}
                      className="textFieldWidth"
                      name="HowManyDays"
                      value={editFormData?.days}
                      onChange={onChangeDays}
                      required={true}
                    ></Input>
                    {editFormData?.days === "" ||
                      (editFormData?.days === null && createFlag && (
                        <span className="required-label">
                          This field is required!
                        </span>
                      ))}
                  </div>
                )}

              {editFormData?.notification_type_id === emailType && (
                <div className="col-md-6">
                  <Input
                    type={"text"}
                    title={"Subject "}
                    className="textFieldWidth"
                    name="Subject"
                    value={editFormData?.subject}
                    onChange={onChangeSubject}
                    placeholder="Subject "
                    required={true}
                  ></Input>
                  {editFormData?.subject === "" && createFlag && (
                    <span className="required-label">
                      This field is required!
                    </span>
                  )}
                </div>
              )}
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div className="doc-editor-container">
                  <TextEditor
                    data={getContent()}
                    config={sampleConfig}
                    onChange={onChangeDescription}
                    disabled
                  />
                </div>

                {editFormData?.content === "" && createFlag && (
                  <span className="required-label">
                    This field is required!
                  </span>
                )}
              </div>
            </div>

            <div className="col"></div>

            <div className="row mt-3">
              <div className="col">
                <Button
                  className="previous btnAlign colorOutline"
                  onClick={handleSubmit}
                  kind="primary"
                >
                  {isLoading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  Submit
                </Button>
                <Button
                  className="backButton"
                  onClick={handleBack}
                  kind="secondary"
                >
                  Back
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Fragment>
  );
};

export default EditAlert;
