export enum HttpMethods {
  GET = "get",
  PUT = "put",
  POST = "post",
  DELETE = "delete",
  PATCH = "patch",
}

export enum URLS {
  SSO_LOGIN = "sso-login",
  SSO_TOKEN = "auth/sso-login-token",
  USER_LIST = "setup/users/list",
  DELETE_USER = "setup/user/delete?id=",
  EDIT_USER = "setup/user/edit?id=",
  USER_DETAIL = "setup/users/detail/",
  USER_SEARCH = "get-graph-user/",
  OSS_LIST = "oss-list/frontend",
  REFRESH = "refresh",
  CLIENTS = "setup/client/list",
  CLIENTDETAILS = "/setup/clientid/list/",
  PRECISION_CLIENTS = "/setup/precisiondata/clients",
  ADD_CLIENT = "setup/client/create",
  INDUSTRIES = "setup/client/industry",
  DUPLICATE_CLIENT = "setup/client/duplicate_name",
  EDIT_CLIENT = "setup/client/edit",
  DELETE_CLIENT = "setup/client/delete",
  USER_TEST = "api/test",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "signup",
  USER_INFO = "auth/user",
  DESIGNATION = "setup/users/designation",
  ROLES = "role-menu-permissions",
  FEATURE_PERMISION = "feature-permissions/",
  PRACTICE = "setup/users/practice",
  Role = "roles/list",
  DUPLICATE_USER = "setup/users/duplicacy?user_email=",
  USER_REGISTER = "setup/user/register",
  AUDIT_CLIENTS = "audit_planning/client",
  AUDIT_INFO = "audit_planning/data",
  AUDIT_PROJECTS_DROPDOWN = "audit_planning/projects/dropdown",
  AUDIT_DROPDOWNS = "audit_planning/audit_dropdowns",
  AUDIT_SCOPE_DROPDOWN = "audit_planning/scope/dropdown",
  AUDIT_SUBPROCESS_DROPDOWN = "audit_planning/scope/subprocess",
  AUDIT_SCOPE_MASTERDATA = "audit_planning/scope/masterdata",
  ADD_AUDIT = "audit_planning/scope/add",
  CREATE_DRAFT = "audit_planning/scope/draft",
  COMPLETE_DRAFT = "audit_planning/scope/edit-data?plan_id=",
  DELETE_AUDIT = "audit_planning/scope/delete?id=",
  EDIT_AUDIT = "audit_planning/scope/edit?id=",
  ADD_FEEDBACK = "feedback/add",
  FEEDBACK_LIST = "feedback/module/list",
  FOLLOWUP_CLIENTS = "followup/client",
  FOLLOWUP_ISSUE_STATUS = "followup/issue/status/filtered?client_id=",
  FOLLOWUP_PROJECTS = "followup/projects/dropdown",
  FOOLOWUP_SCOPE_DROPDOWN = "followup/scope/dropdown",
  FOLLOWUP_OBSERVATIONS_DROPDOWN = "followup/observations/dropdowns",
  FOLLOWUP_OBSERVATIONS_MASTERDATA = "followup/observations/masterdata",
  ADD_FOLLOWUP = "followup/add",
  FOLLOWUP_OBSERVATIONS_LIST = "followup/observations/list",
  FOLLOWUP_COMMENTS_LIST = "followup/observations/comments/list",
  FOLLOWUP_FILE_DOWNLOAD = "followup/download-evidence-doc",
  EDIT_FOLLOWUP = "followup/edit?row_id=",
  REPORTING_TEMPLATE_DOWNLOAD = "reporting/download/template?template_name=",
  REPORTING_MASTERDATA = "reporting/masterdata/dropdown?plan_id=",
  REPORTING_SUBPROCESS = "reporting/scope/subprocess",
  ADD_OBSERVATION_BULK = "reporting/data/bulk",
  REPORTING_OBSERVATION_MASTERDATA = "reporting/observations/masterdata",
  DELETE_REPORT = "reporting/data/delete?row_id=",
  EDIT_REPORT = "reporting/data/edit?row_id=",
  REPORTING_OBSERVATION_MASTERDATA_CATELOG = "reporting/observations/masterdata/catalogue/",
  REPORT_CLIENT_LIST = "reporting/client/list",
  REPORT_PROJECT_DROPDOWN = "reporting/client/projects/dropdown/",
  REPORT_PROJECTS = "reporting/client/projects",
  REPORT_PROJECT_PROCESS_DROPDOWN = "reporting/client/projects/process/dropdown",
  REPORT_PROJECT_SUBPROCESS_DROPDOWN = "reporting/client/projects/subprocess/dropdown",
  ADD_REPORT = "reporting/data/add",
  REPORTING_CLIENTS = "reporting/client/",
  REPORTING_PROJECTS_DROPDOWN = "reporting/projects/dropdown",
  REPORTING_DOWNLOAD_LIST = "reporting/download_list",
  REPORTING_DONLOAD_ACTION = "reporting/approve",
  REPORTING_APPROVED_DOWNLOAD = "reporting/download_approved/",
  REPORTING_SCOPE_DROPDOWN = "reporting/scope/dropdown",
  REPORT_DOWNLOAD = "reporting/download",
  REPORT_APPROVER_LIST = "reports/appover_list/",
  REPORT_GET_AUDITEES = "reporting/get_auditees",
  PROJECT_DETAILS = "setup/project_mgmt/project/details",
  EDIT_PROJECT = "setup/project_mgmt/edit",
  PROJECT_USERS = "setup/project_mgmt/user/list",
  PROJECT_CLIENT_DROPDOWN = "setup/project_mgmt/client/list",
  PROJECT_TYPE_DROPDOWN = "setup/client/iatype",
  PROJECT_PARTNER_DROPDOWN = "setup/project_mgmt/partner/list",
  PROJECT_MANAGER_DROPDOWN = "setup/project_mgmt/manager/list",
  PROJECT_LIST = "setup/project_mgmt/project/list",
  ADD_PROJECT = "setup/project_mgmt/add",
  PROJECT_DOC_DOWNLOAD = "setup/project_mgmt/project/document",
  DELETE_PROJECT = "setup/project_mgmt/delete?id=",
  NOTIFICATION_LIST = "notifications/list",
  NOTIFICATION_UPDATE = "notifications/status/update",
  NOTIFICATION_TYPE = "notifications/type/list",
  NOTIFICATION_SUB_TYPE = "notifications/sub-type/list",
  NOTIFICATION_OPERATION = "notifications/operations/list",
  NOTIFICATION_MODULE = "feedback/module/list",
  NOTIFICATION_SUBMODULE = "base/sub-modules",
  NOTIFICATION_EDIT = "notifications/edit",
  NOTIFICATION_READ = "notification/read",
  AUDIT_MANAGEMENT_DEPARTMENT_TEMPLATE_DOWNLOAD = "department_management/department/template?template_name=",
  AUDIT_MANAGEMENT_DEPARTMENT_LIST = "department_management/department/list",
  AUDIT_MANAGEMENT_DEPARTMENT_DUPLICATE = "department_management/department/duplicate?department_name=",
  AUDIT_MANAGEMENT_DEPARTMENT_EDIT = "department_management/department/edit/",
  AUDIT_MANAGEMENT_DEPARTMENT_ADD = "department_management/department/add",
  AUDIT_MANAGEMENT_DEPARTMENT_ADD_BULK = "department_management/department/bulkadd",
  AUDIT_MANAGEMENT_PROCESS_TEMPLATE_DOWNLOAD = "process_management/process/template?template_name=",
  AUDIT_MANAGEMENT_PROCESS_LIST = "process_management/process/list",
  AUDIT_MANAGEMENT_PROCESS_DUPLICATE = "process_management/process/duplicate?process_name=",
  AUDIT_MANAGEMENT_PROCESS_EDIT = "process_management/process/edit/",
  AUDIT_MANAGEMENT_PROCESS_ADD = "process_management/process/add",
  AUDIT_MANAGEMENT_PROCESS_ADD_BULK = "process_management/process/bulkadd",
  AUDIT_MANAGEMENT_TYPE_TEMPLATE_DOWNLOAD = "type_management/type/template?template_name=",
  AUDIT_MANAGEMENT_TYPE_LIST = "type_management/type/list",
  AUDIT_MANAGEMENT_TYPE_DUPLICATE = "type_management/type/duplicate?type_name=",
  AUDIT_MANAGEMENT_TYPE_EDIT = "type_management/type/edit/",
  AUDIT_MANAGEMENT_TYPE_ADD = "type_management/type/add",
  AUDIT_MANAGEMENT_TYPE_ADD_BULK = "type_management/type/bulkadd",
  AUDIT_MANAGEMENT_SUBPROCESS_PROCESS_LIST = "subprocess_management/process/list",
  AUDIT_MANAGEMENT_SUBPROCESS_LIST = "subprocess_management/subprocess/list?process_id=",
  AUDIT_MANAGEMENT_SUBPROCESS_PROCESS_DROPDOWN_LIST = "reporting/masterdata/dropdown",
  AUDIT_MANAGEMENT_SUBPROCESS_DUPLICATE = "subprocess_management/subprocess/duplicate?subprocess_name=",
  AUDIT_MANAGEMENT_SUBPROCESS_EDIT = "subprocess_management/subprocess/edit/",
  AUDIT_MANAGEMENT_SUBPROCESS_ADD = "subprocess_management/subprocess/add",
  AUDIT_MANAGEMENT_CATALOGUE_ADD_BULK = "observations-library/upload-excel",
  AUDIT_MANAGEMENT_CATALOGUE_OVERVIEW = "observations-library/overview",
  AUDIT_MANAGEMENT_CATALOGUE_TEMPLATE_DOWNLOAD = "observations-library/download-template",
  AUDIT_MANAGEMENT_AUDIT_TRACKER = "audit-tracker/list?page_number=",
  AUDIT_MANAGEMENT_LOCATION_TEMPLATE_DOWNLOAD = "location-management/location/template?template_name=",
  AUDIT_MANAGEMENT_LOCATION_LIST = "location-management/location/list",
  AUDIT_MANAGEMENT_LOCATION_DUPLICATE = "location-management/location/duplicate?location_name=",
  AUDIT_MANAGEMENT_LOCATION_EDIT = "location-management/location/edit/",
  AUDIT_MANAGEMENT_LOCATION_ADD = "location-management/location/add",
  AUDIT_MANAGEMENT_LOCATION_ADD_BULK = "location-management/location/bulkadd",
  AUDIT_MANAGEMENT_RISK_CATALOGUE_TEMPLATE_DOWNLOAD = "risk-controls-catalogue/download-template",
  AUDIT_MANAGEMENT_RISK_CATALOGUE_ADD_BULK = "risk-controls-catalogue/upload-excel",
  AUDIT_MANAGEMENT_RISK_CATALOGUE_OVERVIEW = "risk-controls-catalogue/overview",
  PRECISION_DATA = "setup/precisiondata/projects",
  PRECISION_TASKS = "setup/precisiondata/tasks",
  PORJECT_MASTERDATA = "setup/project_mgmt/masterdata",
  DATA_REQUEST_TYPE = "audit-execution/data-request/type/list",
  DATA_REQUEST_CREATE = "audit-execution/data-request/create",
  DATA_REQUEST_LIST = "audit-execution/data-request/list",
  DATA_REQUEST_DOCS = "audit-execution/data-request/details",
  DATA_REQUEST_DOWNLOAD_DOCS = "audit-execution/data-request/download-doc",
  DATA_REQUEST_STATUS = "audit-execution/data-request/status/list",
  DATA_REQUEST_EDIT = "audit-execution/data-request/edit/",
  AUDITPROJECTPLANS = "audit_planning/project_plans/",
  AUDITEXECUTIONCONTROLS = "field_work/get_scope_controls/",
  ADDCONTROLMAPPING = "field_work/add",
  FIELDWORKACTION = "field_work/scope_approval",
  AUDITAPPROVALLIST = "field_work/audit_approval_list",
  AUDITAPPOVAL = "field_work/audit_approval",
  CONTROLLIST = "risk-control-mapping/control-list",
  EDITSCOPECONTROLS = "risk-control-mapping/edit",
  EDITSCOPECONTROLDETAIL = "risk-control-mapping/edit-control",
  VALIDATEPLANCONTROLS = "risk-control-mapping/check-controls",
  CONFIRMPLANCONTROLS = "risk-control-mapping/confirm-controls",
  ALERT_CLIENTS = "notifications/clients",
  ALERT_PROJECTS = "notifications/projects/dropdown",

  TIMELINEDATA = "dashboard/project-audit-plan-timeline-data",
  PROJECTOVERVIEW = "dashboard/project-scope-overview-data",
  CONTROLSTATUS = "dashboard/controls-stages-status-data",
  PROCESSWISEPROGRESS = "dashboard/process-wise-progress-controls-data",
  AUDITORWISEPROGRESS = "dashboard/auditor-wise-progress-controls-data",
  DATAREQUESTSTATUS = "dashboard/data-request-status-data",
  DATAREQUESTSUBPROCESSAGEING = "dashboard/data_request-subprocess-ageing-data",
  DATAREQUESTAUDITEEAGEING = "dashboard/data-request-auditee-ageing-data",
  REPORTINGCONTROLSTATUS = "dashboard/reporting-control-testing-status-data",
  REPORTINGPROCESSWISERATING = "dashboard/reporting-process-wise-rating-data",
  REPORTINGPROCESSWISEGAPCATEGORYRATING = "dashboard/reporting-process-wise-gap-category-rating-data",
  REPORTINGRATINGCOUNTBYPROCESS = "dashboard/reporting-rating-count-by-process-table-data",
  REPORTINGRATINGCOUNT = "dashboard/reporting-rating-count-table-data",
  FOLLOWUPOBSERVATIONSAUDITSTATUS = "dashboard/follow-up-observations-audit-status-data",
  FOLLOWUPOBSERVATIONSRATING = "dashboard/follow-up-observations-rating-data",
  FOLLOWUPOBSERVATIONSBYACTIONOWNER = "dashboard/follow-up-observations-by-action-owner-data",
  FOLLOWUPOBSERVATIONSAGEING = "dashboard/follow-up-observations-ageing-data",
}
